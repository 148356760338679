/* You can add global styles to this file, and also import other style files */

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";


$primary: #2B3948;
$secondary: #818A91;
// $success: #00ff00;
// $info: #00ff00;
// $warning: #00ff00;
// $danger: #00ff00;
// $light: #00ff00;
// $dark: #00ff00;


$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "danger": $danger
);


@import "~bootstrap/scss/bootstrap";


html,
body {
  position: relative;
  width: 100%;
  height: 100%;
}

body {
  color: #333;
  margin: 0;
  padding: 8px;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

a {
  color: rgb(0, 100, 200);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:visited {
  color: rgb(0, 80, 160);
}

label {
  display: block;
}

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  -webkit-padding: 0.4em 0;
  padding: 0.4em;
  margin: 0 0 0.5em 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 2px;
}

input:disabled {
  color: #ccc;
}

button {
  color: #333;
  background-color: #f4f4f4;
  outline: none;
}

button:disabled {
  color: #999;
}

button:not(:disabled):active {
  background-color: #ddd;
}

button:focus {
  border-color: #666;
}

main {
  padding: 1em;
  max-width: 240px;
  margin: 0 auto;
}

h1 {
  color: $primary;
  text-transform: uppercase;
  // font-size: 2em;
  // font-weight: 100;
}

h2 {
  color: $primary;
}

div.has-fixed-header p,
div.has-fixed-header li {
  text-align: justify;
  font-size: 1.2em;
  font-weight: 150;
}

@media (min-width: 640px) {
  main {
    max-width: none;
  }
}

.fixed-top-2 {
  margin-top: 74px;
}

.has-fixed-header {
  margin-top: 74px;
}

.has-2-fixed-headers {
  margin-top: 148px;
}

.iframe-100 {
  width: 100%;
}

.navbar-dark .nav-item a.nav-link {
  color: rgba($light, .65)
}

.navbar-dark .nav-item a.nav-link.active {
  color: $light;
}

.navbar-dark .nav-item a.nav-link:hover {
  color: rgba($light, .85);
  text-decoration: none;
}

.hsl {
  /*
    hs-sidebar, .hs-sidebar-list, .hs-sidebar-item {
    background-color: rgb(240, 240, 240)
  }
    .card-header {
    color: $primary
  }
  */
}